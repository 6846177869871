<template>
  <div>
    <pl-block>
      <PlForm
        v-model="searchForm"
        mode="search"
        :fields="serchFields()"
        @submit="handleSearch"
        @reset="handleSearch"
      />
    </pl-block>
    <pl-block>
      <PlTable
        v-model:pagination="pagination"
        v-loading="loading.getReservoirList"
        :data="reservoirList"
        :columns="TABLECOLUMN"
        :show-table-setting="true"
        @update:pagination="getReservoirList"
      >
        <template #receiptNo="{row}">
          <nh-link
            :to="{}"
            :text="row.receiptNo"
            @click="handleDetails(row)"
          />
        </template>
      </PlTable>
    </pl-block>
    <nh-drawer
      v-model="drawerVisible"
      :before-close="handleClose"
      destroy-on-close
      title="订单基本信息"
      size="1111px"
      :track="{
        name_zh: '异常中心/入库订单/抽屉-详情',
        trackName: $route.path
      }"
    >
      <InWarehouseDrawer v-if="drawerVisible" :receipt-order-no="receiptOrderNo" />
    </nh-drawer>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';

import moment from '@/utils/moment';
import ExceptionCenter from '@/constant/pageNames/exceptionCenter';
import { TABLECOLUMN, serchFields } from './fileds';
import { recordPage } from './api';
import InWarehouseDrawer from '../../in-warehouse/inbound-order/detail/index.vue';

export default {
  name: ExceptionCenter.INBOUND_ORDER,
  components: { InWarehouseDrawer },
  mixins: [loadingMixin],
  provide: {
    nameZh: '异常中心/入库订单/',
  },
  data() {
    return {
      TABLECOLUMN,
      serchFields,
      reservoirList: [],
      searchForm: {
        handleStatus: 'NONE_HANDLE',
      },
      visible: false,
      loading: {
        getReservoirList: false,
      },
      receiptOrderNo: null,
      drawerVisible: false,
    };
  },
  created() {
    this.getReservoirList();
  },
  methods: {
    handleSearch() {
      this.pagination.page = 1;
      this.getReservoirList();
    },
    async getReservoirList() {
      const searchForm = {
        ...this.searchForm,
        receiptCreateTime: this.getTime('leftDateTime', 'rightDateTime', this.searchForm.receiptCreateTimeArr),
        registerTime: this.getTime('leftDateTime', 'rightDateTime', this.searchForm.registerTimeArr),
        acceptanceTime: this.getTime('leftDateTime', 'rightDateTime', this.searchForm.acceptanceTimeArr),
        acceptanceCommitTime: this.getTime('leftDateTime', 'rightDateTime', this.searchForm.acceptanceCommitTimeArr),
        upperEndTime: this.getTime('leftDateTime', 'rightDateTime', this.searchForm.upperEndTimeArr),
      };
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const result = await recordPage(searchForm, pagination);
      this.reservoirList = result.records;
      this.pagination.total = result.total;
    },
    getTime(beginTime, endTime, arr) {
      if (Array.isArray(arr)) {
        return {
          [beginTime]: moment.startOf(moment.toTimestame(arr[0])),
          [endTime]: moment.ensOf(moment.toTimestame(arr[1])),
        };
      }
      return {};
    },
    filterLabel(val, arr) {
      const filterArr = arr && arr.find((item) => item.value === val);
      return filterArr ? filterArr.label : '';
    },
    handleDetails({ receiptNo }) {
      this.receiptOrderNo = receiptNo;
      this.drawerVisible = true;
    },
    handleClose() {
      this.drawerVisible = false;
      this.getTableData();
    },
  },
};
</script>
<style scoped>
.action {
  padding: 15px 0;
}
</style>
